<template>
  <v-btn text rounded :to="$props.url" :href="$props.href" class="plain-button" @click="$emit('click')" :disabled="isDisabled">
    <slot name="default"></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'PlainButton',
  props: {
    url: {
      required: false
    },
    href: {
      required: false
    },
    waitingForEndOfAction: {
      Boolean,
      default: false
    }
  },
  data() {
    return {
      isDisabled: this.$props.waitingForEndOfAction
    }
  },
  watch: {
    waitingForEndOfAction() {
      this.isDisabled = this.$props.waitingForEndOfAction
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app .plain-button
  color: $primary
  text-decoration: none !important
  text-transform: none !important
</style>
