<template>
  <v-btn rounded :to="$props.url" :href="$props.href" class="primary-button" @click="$emit('click')" :disabled="isDisabled">
    <v-progress-circular v-if="$props.waitingForEndOfAction" indeterminate size="20" color="white" class="mr-1"></v-progress-circular>
    <slot name="default"></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: {
    url: {
      required: false
    },
    href: {
      required: false
    },
    waitingForEndOfAction: {
      Boolean,
      default: false
    }
  },
  data() {
    return {
      isDisabled: this.$props.waitingForEndOfAction
    }
  },
  watch: {
    waitingForEndOfAction() {
      this.isDisabled = this.$props.waitingForEndOfAction
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app .primary-button
  background-color: $primary !important
  color: $sheet-background !important
  box-shadow: none !important
  text-decoration: none !important
  text-transform: none !important
  &:hover
    box-shadow: $box-shadow !important
  &:active
    box-shadow: $box-shadow !important
  .v-icon
    color: $sheet-background
</style>
