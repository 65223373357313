<template>
  <div id="folder-index">
    <v-row no-gutters>
      <v-col class="col-md-10">
        <Headline />
      </v-col>
      <v-col class="col-md-2 text-right">
        <PrimaryButton url="/folders/add">{{ $t('folder_index.add_folder') }}</PrimaryButton>
      </v-col>
    </v-row>
    <FolderIndex />
  </div>
</template>

<script>
import Headline from '@/components/app/Headline'
import PrimaryButton from '@/components/app/PrimaryButton.vue'
import FolderIndex from '@/components/folder/FolderIndex'

export default {
  components: {
    Headline,
    PrimaryButton,
    FolderIndex
  }
}
</script>
