<template>
  <v-list-item :href="$props.href" @click="$emit('click')">
    <v-list-item-icon>
      <v-icon>{{ $props.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ $props.text }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'ActionMenuItem',
  props: {
    href: {
      String,
      required: false
    },
    icon: {
      String,
      required: true
    },
    text: {
      String,
      required: true
    }
  }
}
</script>

<style lang="sass">
@import '~@/sass/variables.sass'

#app .action-menu-items
  .v-list-item__icon
    margin-right: 16px
  .v-list-item__title
    padding-top: 2px
</style>
