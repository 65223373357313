<template>
  <v-dialog
    v-model="isVisible"
    :scrollable="$props.isScrollable"
    :max-width="$props.dialogMaxWidth"
    @click:outside="closeHandler"
    :persistent="$props.waitingForEndOfAction"
    :close-on-back="!$props.waitingForEndOfAction"
  >
    <v-card>
      <v-card-title class="ps-6">
        <slot name="title"></slot>
      </v-card-title>
      <v-card-text class="pt-4 text-body-1">
        <slot name="content"></slot>
      </v-card-text>
      <v-spacer />
      <v-card-actions class="pe-6 pb-4">
        <v-spacer />
        <PlainButton class="dialog-close-btn" @click.native="closeHandler" :waiting-for-end-of-action="$props.waitingForEndOfAction">
          {{ $t('misc.cancel') }}
        </PlainButton>
        <PrimaryButton class="dialog-confirm-btn" @click.native="confirmHandler" :waiting-for-end-of-action="$props.waitingForEndOfAction">
          <slot name="buttonText"></slot>
        </PrimaryButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PlainButton from '@/components/app/PlainButton'
import PrimaryButton from '@/components/app/PrimaryButton'

export default {
  name: 'Dialog',
  components: {
    PrimaryButton,
    PlainButton
  },
  props: {
    visible: {
      Boolean,
      default: false
    },
    isScrollable: {
      Boolean,
      default: false
    },
    dialogMaxWidth: {
      String,
      default: ''
    },
    waitingForEndOfAction: {
      Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: this.$props.visible
    }
  },
  watch: {
    visible() {
      this.isVisible = this.$props.visible
    }
  },
  methods: {
    closeHandler() {
      if (this.$props.waitingForEndOfAction) {
        return
      }
      this.$emit('dialog:close')
    },
    confirmHandler() {
      this.$emit('dialog:confirmed')
    }
  }
}
</script>
