<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon :small="small" :regular="regular">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list class="action-menu-items" dense>
        <slot name="actionMenuItems"></slot>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'ActionMenu',
  computed: {
    isCompactView() {
      return this.$store.getters['compact_view/isCompactView']
    },
    small() {
      return this.isCompactView === true
    },
    regular() {
      return this.isCompactView === false
    }
  }
}
</script>
