<template>
  <span class="cropped-content" :title="this.value" :style="cssProps">{{ this.value.toString() }}</span>
</template>

<script>
export default {
  name: 'Crop',
  props: {
    value: {
      type: [String, Number],
      default: 'value'
    },
    maxWidth: {
      type: String,
      default: '30px'
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssProps() {
      return { 'max-width': this.maxWidth, display: this.inline ? 'inline-block' : 'block' }
    }
  }
}
</script>

<style lang="sass">
span.cropped-content
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
</style>
