let ReferenceResolverMixin = {
  methods: {
    getSaleTemplateNameById(id) {
      return this.$store.getters['sale_template/getSaleTemplateNameById'](id)
    },
    getBannerNameById(id) {
      return this.$store.getters['sale_banner/getBannerNameById'](id)
    },
    getSaleBanners() {
      return this.$store.getters['sale_banner/getAll']()
    },
    getSaleUrlNameById(id) {
      return this.$store.getters['sale_url/getSaleUrlNameById'](id)
    },
    getSaleUrlLinkById(id) {
      return this.$store.getters['sale_url/getSaleUrlLinkById'](id)
    },
    getSaleUrls() {
      return this.$store.getters['sale_url/getAll']()
    },
    getSaleTextById(id) {
      return this.$f.implode(this.$store.getters['sale_text/getSaleTextById'](id), ' ')
    },
    getSaleTexts() {
      return this.$store.getters['sale_text/getAll']()
    }
  }
}

export default ReferenceResolverMixin
