<template>
  <div>
    <Grid
      data-store-action="folder/fetchData"
      :additional-api-arguments="apiArgs"
      :headers="headers"
      ref="grid"
      :enable-pagination="true"
      :default-sort-by="defaultSortBy"
      :default-sort-desc="defaultSortDesc"
      :enabled-columns="columnSwitchOptions.activeColumns"
      form-name="folder-index-form"
    >
      <template v-slot:[`item.name`]="{ item }">
        <span class="name">
          <router-link :to="{ name: 'domains_index', query: { folder: item.id } }">{{ $f.crop(item.name, 30) }}</router-link>
        </span>
      </template>
      <template slot="filters">
        <v-col class="col-12 col-sm-6 col-md-2 filter-search">
          <v-text-field
            :label="$t('folder_index.search')"
            :value="apiArgs.restarting.search.value"
            dense
            filled
            hide-details
            placeholder=""
            @change="apiArgs.restarting.search.value = $event"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col class="col-12 col-sm-6 col-md-2 filter-column-presets">
          <v-select
            :items="columnSwitchOptions.available"
            :label="$t('domain_index.columns')"
            :menu-props="{ contentClass: 'filter-column-presets-menu', bottom: true, offsetY: true }"
            :value="columnSwitchOptions.activeColumns"
            dense
            filled
            hide-details
            item-text="text"
            item-value="columns"
            id="filter-column-presets-select"
            @change="columnSwitchOptions.activeColumns = $event"
            prepend-inner-icon="mdi-view-column-outline"
          ></v-select>
        </v-col>
      </template>
      <template v-slot:[`item.use_settings`]="{ item }">
        <span class="use_settings">{{ item.use_settings ? 'Active' : 'Inactive' }}</span>
      </template>
      <template v-slot:[`item.sellurl`]="{ item }">
        <Crop max-width="100px" :value="getSaleUrlLinkById(item.sellurl)" />
      </template>
      <template v-slot:[`item.note`]="{ item }">
        <Crop max-width="200px" :value="item.note" v-if="item.note !== null" />
      </template>
      <template v-slot:[`item.forsale_linkstyle`]="{ item }">
        <Crop max-width="100px" :value="getBannerNameById(item.forsale_linkstyle)" />
      </template>
      <template v-slot:[`item.forsale_template`]="{ item }">
        <Crop max-width="100px" :value="getSaleTemplateNameById(item.forsale_template)" />
      </template>
      <template v-slot:[`item.custom_text`]="{ item }">
        <Crop max-width="80px" :value="getSaleTextById(item.custom_text)" />
      </template>
      <template v-slot:[`item.sellurl_name`]="{ item }">
        <Crop max-width="100px" :value="getSaleUrlNameById(item.sellurl)" />
      </template>
      <template v-slot:[`item.sellurl_url`]="{ item }">
        <Crop max-width="100px" :value="getSaleUrlLinkById(item.sellurl)" />
      </template>
      <template v-slot:[`item.forsale_type`]="{ item }">
        <span class="forsale_type">{{ $t('domain_index.forsale_type_' + item.forsale_type) }}</span>
      </template>
      <template v-slot:[`item.min_offer`]="{ item }">
        {{ $f.price(item.min_offer, item.currency) }}
      </template>
      <template v-slot:[`item.captcha`]="{ item }">
        <span class="captcha">{{ $f.boolean(item.captcha) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="column-actions">
          <ActionMenu>
            <template v-slot:actionMenuItems>
              <ActionMenuItem
                :text="$t('actions.edit_folder')"
                icon="mdi-pencil-outline"
                @click="openPage(item, 'folders_edit')"
                class="folder-edit"
              />
              <ActionMenuItem
                :text="$t('actions.delete_folder')"
                icon="mdi-delete-outline"
                @click="openDialog(item, 'showDeleteDialog')"
                class="folder-delete"
              />
            </template>
          </ActionMenu>
        </div>
      </template>
    </Grid>

    <Dialog :visible="showDeleteDialog" dialogMaxWidth="650" @dialog:close="closeDialog('showDeleteDialog')" @dialog:confirmed="deleteFolder">
      <template v-slot:title v-if="selectedItem != null">
        {{ $t('folder_index.folder_delete_info') }}
      </template>
      <template v-slot:content v-if="selectedItem != null">
        {{ selectedItem.name }}
      </template>
      <template v-slot:buttonText>
        {{ $t('misc.delete') }}
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/others/Dialog'
import Grid from '@/components/grid/Grid'
import ReferenceResolverMixin from '@/components/grid/ReferenceResolverMixin'
import Crop from '@/components/others/Crop'
import GridBaseMixin from '@/components/grid/GridBaseMixin'
import ActionMenuItem from '@/components/app/ActionMenuItem'
import ActionMenu from '@/components/app/ActionMenu'

export default {
  mixins: [GridBaseMixin, ReferenceResolverMixin],
  components: {
    ActionMenu,
    ActionMenuItem,
    Crop,
    Grid,
    Dialog
  },
  mounted() {
    this.$store.dispatch('sale_banner/fetchData', { per_page: 1000 })
    this.$store.dispatch('sale_url/fetchData', { per_page: 1000 })
    this.$store.dispatch('sale_text/fetchData', { per_page: 1000 })
    this.$store.dispatch('sale_template/fetchData', { per_page: 1000 })
  },
  data() {
    let parkingColumnSet = {
      text: 'Parking',
      columns: ['name', 'note', 'use_settings', 'cdate', 'actions']
    }

    let salesColumnSet = {
      text: 'Sale',
      columns: [
        'name',
        'forsale_type',
        'forsale_linkstyle',
        'forsale_template',
        'custom_text',
        'sellurl_name',
        'sellurl_url',
        'min_offer',
        'captcha',
        'cdate',
        'actions'
      ]
    }

    return {
      tab: null,
      tabItems: ['folder_settings', 'folder_sale_settings', 'folder_parked_settings'],
      showDeleteDialog: false,
      selectedItem: null,
      headers: [
        { text: '', value: 'actions', sortable: false },
        { text: this.$t('folder_index.name'), value: 'name', sortable: true },
        {
          text: this.$t('folder_index.use_settings'),
          value: 'use_settings',
          sortable: false,
          description: this.$t('folder_index.use_settings_description')
        },
        { text: this.$t('folder_index.note'), value: 'note', sortable: false },
        { text: this.$t('folder_index.useimprint'), value: 'useimprint', sortable: false },
        { text: this.$t('folder_index.sale_type'), value: 'forsale_type', sortable: false },
        { text: this.$t('folder_index.sale_template'), value: 'forsale_template', sortable: false },
        { text: this.$t('folder_index.sale_banner'), value: 'forsale_linkstyle', sortable: false },
        { text: this.$t('folder_index.sellurl_name'), value: 'sellurl_name', sortable: false },
        { text: this.$t('folder_index.sellurl_url'), value: 'sellurl_url', sortable: false },
        { text: this.$t('folder_index.custom_text'), value: 'custom_text', sortable: false },
        { text: this.$t('folder_index.min_offer'), value: 'min_offer', sortable: false, align: 'right' },
        { text: this.$t('folder_index.captcha'), value: 'captcha', sortable: false },
        { text: this.$t('folder_index.added'), value: 'cdate', sortable: false }
      ],
      columnSwitchOptions: {
        activeColumns: salesColumnSet.columns,
        available: [parkingColumnSet, salesColumnSet]
      },
      data: {
        total: 0
      },
      defaultSortBy: ['name'],
      defaultSortDesc: [false],
      apiArgs: {
        restarting: {
          search: {
            value: this.queryParamOrDefaultValue('search', null),
            check: search => search !== '',
            argumentProvider: (apiArguments, search) => {
              if (search !== '') {
                apiArguments['name'] = search
              }
            }
          }
        }
      }
    }
  },
  methods: {
    openPage(item, page) {
      this.$router.push({ name: page, params: { folder_id: item.id } })
    },
    openDialog(item, component) {
      this.setSelectedFolderAndOpenComponent(item, component)
    },
    closeDialog(component) {
      this.resetSelectedFolderAndCloseComponent(component)
    },

    deleteFolder() {
      this.$store
        .dispatch('folder/delete', this.selectedItem.id)
        .then(async () => {
          await this.$store.dispatch('snackbar/showSuccessMessage', { message: this.$t('folder_index.folder_deleted') })

          this.resetSelectedFolderAndCloseComponent('showDeleteDialog')
          this.$refs.grid.getDataFromApi() // Calling Method from inside Grid component
        })
        .catch(() => {
          this.$parent.$emit('api_error')
        })
    },
    setSelectedFolderAndOpenComponent(item, component) {
      this.selectedItem = { ...item }
      this[component] = true
    },
    resetSelectedFolderAndCloseComponent(component) {
      this.selectedItem = null
      this[component] = false
    }
  }
}
</script>
